.content, .whiteModeContent
  display: flex
  flex-direction: column
  margin: 30px 0
  cursor: pointer
  .main
    width: 100%
    height: 116px
    border-radius: 3px
    box-shadow: 0 6px 12px 0 rgba(31, 4, 48, 0.1)
    background-color: #3c3c3c
    display: flex
    justify-content: space-between
    .LeftCorner
      display: flex
      .left
        width: 44px
        height: 116px
        cursor: pointer

        img
          padding: 47px 11px
          transition: 0.2s

        .imgCLick
          transform: rotate(0deg)
        .imgUnCLick
          transform: rotate(-90deg)
      .body
        display: flex

        .ItemImg
          width: 116px
          height: 116px
          // border: solid 1px #f2f2f2
          img
            background-size: cover
            width: 100%
        .ItemDefault
          width: 116px
          height: 116px
          border: solid 1px #f2f2f2
          background-color: #f09440
          .default
            width: 48px
            height: 48px
            object-fit: contain
            opacity: 0.22
            padding: 34px
        .itemData
          display: flex
          flex-direction: column
          align-items: flex-start
          flex: 4
          padding: 5px 17px
          .name
            font-size: 16px
            font-weight: bold
            color: #ffffff
          .desc
            font-size: 14px
            font-weight: normal
            color: #a6a6a6
  .RightCorner
    .price
      display: flex
      align-items: flex-end
      flex: 1
      padding: 44px  28px
      .settingsWrapper
        background-color: rgba(0, 0, 0, 0.2)
        width: 40px
        height: 40px
        outline: 0
        border-radius: 20px

      .switch
        height: 21px
        width: 38px
      span
        font-size: 18px
        font-weight: bold
        text-align: right
        color: #ffffff
        flex: 1
        margin: 0 24px
  .itemContent
    transition: 0.2s
  .itemContentEnable
    display: inline
  .itemContentDisable
    display: none
  &.rtl
    .main
      .LeftCorner
        .left
          .imgUnCLick
            transform: rotate(90deg)

.left_active, .whiteModeLeftActive
  background-color: #e0d1ee
.left_inactive,.whiteModeLeftInActive
  background-color: #cccccc

.dta
  padding-top: 22px
  display: flex
  flex-direction: column
  align-self: center
  .is_available
    font-size: 14px
    font-weight: 900
    color: #db3030
    margin: 0 17px
    // margin-top: 22px

.whiteModeContent
  .main
    border-radius: 10px
    box-shadow: 0 2px 24px 0 rgba(31, 4, 48, 0.1)
    background-color: #ffffff
    .LeftCorner
      .body
        .itemData
          .name
            color: #1a1a1a
          .desc
            color: #1a1a1a
  .RightCorner
    .price
      span
        color: #1a1a1a
.whiteModeLeftActive
  background-color: #ffffff
.whiteModeLeftInActive
  background-color: #ffffff
