
// .dropDownPart
//   width: 260px !important
//   display: flex
//   flex-direction: column
//   margin: 0
.switch
  font-size: 1.8rem !important
  // border: 1px solid $color-border !important
  min-height: 5.5rem !important
  white-space: normal !important
  margin: 0 !important
  width: 100%
  border-radius: 5px !important
  padding: 0 2rem !important
  outline: none !important
  background-color: white !important
  display: flex !important
  align-items: center !important
  justify-content: space-between !important
  color: black !important
  box-shadow: none !important
  .switch::after
    font-size: 2.5rem
  .switch:disabled
    border-radius: 5px !important
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1) !important
    border: solid 1px #e3e3e3 !important
    background: #ebebeb !important
    cursor: default

.menuWrapper
  width: 100%
  .item
    padding: 1rem 2rem
    border-radius: 5px !important
    font-size: 1.8rem
    outline: 0 !important
  .item:hover
    background-color: rgba(254,190,25,.1)
