.wrapper
  display: flex
  flex-direction: column

  .contactSupport
    cursor: pointer
    display: flex
    flex-direction: row
    padding: 24px
    margin-bottom: 8px

    .title
      font-size: 16px
      font-weight: bold
      color: #1a1a1a

    .arrowRight
      transform: rotate(180deg)
      margin: 0 16px

  .contactWrapper
    display: flex
    justify-content: center
    align-items: center
    flex: 1
    margin-bottom: 46px

  &.rtl
    .arrowRight
      transform: none !important
