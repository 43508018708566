.wrapper
  display: flex
  position: fixed
  z-index: 1400
  height: 100%
  width: 100%
  background-color: #41126c
  color: white
  flex-direction: column
  align-items: center
  justify-content: center

  .ordersCount
    border-radius: 50%
    width: 250px
    height: 250px
    background-color: #5b1998
    padding: 30px
    display: flex
    .innerCircle
      font-size: 132px

      display: flex
      justify-content: center
      align-items: center
      border-radius: 50%
      flex: 1
      background-color: #4e1682

  .title
    font-size: 32px
    font-weight: bold

  .subTitle
    margin-top: 12px
    font-size: 24px
    color: rgba(#fff, 0.5)
