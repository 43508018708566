.main, .whiteModeMain
  width: 100%
  padding: 35px 53px
  border: solid 1px #e6e6e6
  background-color: #212121
  .header
    display: flex
    justify-content: space-between
    border-bottom: 1px solid #e6e6e6
    padding: 4px 2px

    .name
      font-size: 18px
      font-weight: bold
      color: #ffffff

  .body
    padding: 32px 40px

.is_available
  font-size: 12px
  font-weight: bold
  color: #db3030
  margin: 0 10px
.whiteModeMain
  background-color: #ffffff
  border: solid 1px #b3b3b3
  border-radius: 3px
  .header
    .name
      color: #1a1a1a
