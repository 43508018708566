.wrapper, .whiteWrapper
  position: relative
  padding-left: 92px

  .nav
    display: flex
    height: 47px
    margin-top: 20px
    padding-bottom: 16px
    overflow-x: auto
    overflow-y: hidden

  .ellipsis
    top: 0
    width: 92px
    // height: 50px
    background-image: linear-gradient(to right, #3c3c3c 57%, rgba(60, 60, 60, 0.55)) !important
    position: absolute
    right: 0

  &.rtl
    padding-left: 0
    padding-right: 92px

    .ellipsis
      left: 0
      right: unset
      background-image: linear-gradient(to right, #3c3c3c 57%, rgba(60, 60, 60, 0.55))
.whiteWrapper
  border-top: 1px solid#f2f2f2
  margin-top: 24px
  padding-left: 122px
  box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.1)
  &.rtl
    padding-left: 115px
    padding-right: 0
