.menu,.whiteModeMenu
  width: 100%
  display: flex
  flex-direction: column
  background-color: #1a1a1a
.emptyResults
  font-size: 20px
  flex: 1
  display: flex
  color: #9b9b9b
  align-items: center
  justify-content: center
  width: 100%
  height: 100%

.whiteModeMenu
  background-color: #ffffff
  .header

    padding: 32px 135px
    background-color: #ffffff
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    .dropDownWrapper
      display: flex
      flex-direction: row
      align-items: center
      justify-content: center
      .dropDown
        width: 305px
        height: 52px
        object-fit: contain
        border-radius: 5px
        border: solid 1px #ebebeb
        background-color: #ffffff
    .title
      font-size: 28px
      font-weight: 900
      color: #000000
