.wrapper
  display: flex
  justify-content: space-between
  align-items: center
  width: 470px
  padding: 20px
  color: #ffffff
  font-weight: bold
  line-height: 1.14
  letter-spacing: .6px
  border-radius: 10px
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, .4)
  height: 64px
  font-sisze: 22px

  img
    margin: 0 8px
    width: 28px
    height: auto

  span
    flex: 1

  &.success
    background-color: #38c172

  &.failure
    background-color: #db3030

  &.rtl
    direction: rtl
    text-align: right
