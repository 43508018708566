.item,.whiteItem
  &,
  &:active,
  &:hover,
  &:focus
    height: 34px
    border-radius: 18px
    font-size: 18px
    text-align: center
    padding: 4px 16px
    cursor: pointer
    white-space: nowrap
    color: rgba(255, 255, 255, 0.6)
    text-decoration: none
.whiteItem
  color: #595959
  &:active,
  &:hover,
  &:focus
    color: #ffffff
    background-color: #f09440

.active, .whiteActive
  &,
  &:active,
  &:hover,
  &:focus
    background-color: #f09440
    color: #ffffff
.whiteActive
  &,
  &:active,
  &:hover,
  &:focus
    background-color: #f09440
    color: #ffffff
.notActive
  &,
  &:active,
  &:hover,
  &:focus
    background-color: none
