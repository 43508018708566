.paymentWrapper
  display: flex
  justify-content: center
  flex-direction: column
  height: 100%
  border-top: 1px solid #e6e6e6
  position: sticky
  bottom: 0
  background-color: inherit
  z-index: 1
  padding: 24px
  .wrapper
    display: flex
    justify-content: space-around

    .total
      display: flex
      flex-direction: column
      align-content: space-between !important
      border-radius: 10px
      .totalTitle
        margin-bottom: 4px
        font-size: 14px
        color: #a6a6a6
      .price
        font-size: 18px
        font-weight: bold
        line-height: 1
        color: #1a1a1a
      .btnWrapper
        padding: 16px 0
        .btn
          width: 128px
          height: 48px
          border-radius: 5px
          background-color: #38c172
          font-size: 16px
          font-weight: bold
          line-height: 1.56
          letter-spacing: 0.11px
          text-align: center
          color: #ffffff
    .payment
      display: flex
      flex-direction: column
      align-content: space-between !important
      border-radius: 10px
      .paymentMethod
        font-size: 14px
        color: #a6a6a6
        margin-bottom: 4px
      .cash
        font-size: 18px
        font-weight: bold
        line-height: 1
        color: #1a1a1a
  .rejectWrapper
    padding: 19px 0
    display: flex
    justify-content: center
    .reject
      width: 128px
      height: 48px
      border-radius: 5px
      background-color: #ffffff
      font-size: 16px
      font-weight: bold
      line-height: 1.56
      letter-spacing: 0.11px
      text-align: center
      color: #db3030


.rtl
  direction: rtl
