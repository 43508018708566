.content
  display: flex
  flex-direction: row
  justify-content: space-between
  width: 100%
  padding-bottom: 8px
  .name
    font-size: 14px
    color: #1a1a1a
    font-size: 14px
    font-weight: bold
  .price
    display: flex
    flex-direction: row
    justify-content: space-between
    span
      font-size: 14px
      font-weight: bold
      text-align: right
      color: #1a1a1a
