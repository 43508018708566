.wrapper, .rtl
  display: flex
  flex-direction: row
  align-items: center
  .dropDownTitle
    margin: 0 8px
    font-size: 17px
    font-weight: 500
    text-align: right
    color: #2d2d2d
.filterWrapper
  display: flex
  flex-direction: row
  justify-content: center
  align-items: item
  border-radius: 5px
  border: solid 1px #ebebeb
  background-color: #ffffff
  width: 305px
  height: 52px
  padding-left: 20px
  padding-right: 15px
  .label
    font-size: 16px
    font-weight: 500
    font-style: normal
    line-height: 0.88
    color: #222222
.selectWrapper
  padding: 16px
  .labelSelected
    font-size: 16px
    font-weight: 500
    font-style: normal
    line-height: 0.88
    color: #222222
.rtl
  direction: rtl
