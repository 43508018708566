.wrapper
  display: flex
  flex-direction: column
  align-content: space-around

  .editWrapper
    display: flex
    justify-content: space-around

    .edit
      cursor: pointer
      display: flex
      padding: 16px
      align-items: center
      justify-content: space-around

      .title
        font-size: 16px
        font-weight: bold
        color: #1a1a1a

      .arrowRight
        padding: 5px
        transform: rotate(180deg)

        // width: 16px
        // height: 16px

      .timeWrapper
        display: flex
        flex-direction: column

        .timeTitle
          font-size: 14px
          color: #1a1a1a

        .secondTitle
          font-size: 18px
          font-weight: bold
          line-height: 1
          letter-spacing: .3px
          color: #1a1a1a

      .clockIcon
        margin: 0 6px
        width: 36px
        height: 38px

  .secondWrapper
    display: flex
    flex-direction: column
    align-content: center

    .timeEditTitle
      font-size: 16px
      font-weight: bold
      text-align: center
      color: #2d2d2d

    .fieldWrapper
      display: flex
      flex-direction: row
      justify-content: center
      padding: 24px

    .btnWrapper
      padding: 0 24px
      display: flex
      flex-direction: row
      justify-content: space-around

      .save
        width: 100%
        height: 48px
        border-radius: 5px
        background-color: #38c172
        font-size: 16px
        font-weight: bold
        line-height: 1.56
        letter-spacing: .1px
        text-align: center
        color: #ffffff
        margin-bottom: 28px
        margin-top: 18px


  &.rtl
    .arrowRight
      transform: none !important
