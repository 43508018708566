.wrapper
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 24px
  .contactSupport
    display: flex
    flex-direction: row
    justify-content: flex-end
    padding: 24px
  .warningWrapper
    margin-top: 12px
    width: 86px
    height: 86px
    border-radius: 43px
    background-color: rgba(219, 48, 48, 0.3)
    display: flex
    justify-content: center
    align-self: center
  .titleWrapper
    display: flex
    flex-direction: column
    align-items: center
    align-self: center
    padding-top: 17px
    .title
      font-size: 22px
      font-weight: bold
      line-height: 1.14
      letter-spacing: 0.16px
      text-align: center
      color: #db3030
    .warning
      padding-top: 8px
      font-size: 16px
      line-height: 1.56
      letter-spacing: 0.11px
      text-align: center
      color: #1a1a1a
  .btnWrapper
    display: flex
    justify-content: center
    flex-direction: row
    padding: 28px 0
    width: 100%
    .backBtn
      width: 220px
      height: 48px
      border-radius: 5px
      border: solid 1px #a6a6a6
      background-color: #ffffff
      font-size: 16px
      font-weight: bold
      line-height: 1.56
      letter-spacing: 0.11px
      text-align: center
      color: #1a1a1a
    .btn
      width: 178.6px
      height: 48px
      border-radius: 5px
      background-color: #db3030
      font-size: 18px
      transition: .2s all
      font-weight: bold
      line-height: 1.39
      letter-spacing: .3px
      text-align: center
      color: #ffffff
      &:disabled
        background-color: #dbdbdb

    // .title
    //   font-size: 16px
    //   font-weight: bold
    //   text-align: right
    //   color: #1a1a1a

    .arrowRight
      margin: 0 16px
