@keyframes collapse-and-disappear
  0%
    transform: scaleX(1)
    transform-origin: 0 0
    opacity: 1

  100%
    transform: scaleX(0)
    transform-origin: 0 0
    opacity: 1
