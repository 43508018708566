.wrapper
  font-size: 14px
  letter-spacing: 0.22px
  text-align: center
  color: #222222
  .topText
    font-weight: bold
  .bottomText
    font-size: 16px
    color: #41126c
  .input
    margin-top: 6px
    margin-bottom: 10px
    width: 116px
    display: flex
    height: 50px
    align-items: center
    text-align: center
    border-radius: 5px
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
    border: solid 1px #a6a6a6
    background-color: #ffffff
    outline: 0
    font-size: 18px
    font-weight: bold
    line-height: 1.5
    letter-spacing: 0.82px
    color: #1a1a1a
  &.invalid
    .input
      border-color: #db3030
  &.valid
    .input
      border-color: #38c172
  &.active
    .input
      border-color: #fdbb11
