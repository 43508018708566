.main, .whiteWrapper
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 92px
  .menuName, .whiteMenuName
    font-size: 26px
    font-weight: bold
    color: #ffffff
  .whiteMenuName
    color: #000000
    font-size: 22px
    font-weight: 500
  .Search
    display: flex
    flex-direction: row
    .searchWrapper, .whiteSearchWrapper
      display: flex
      // background-color: rgba(0, 0, 0, 0)
      flex-direction: row
      border-radius: 5px
      box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.15)
      border: solid 1px rgba(255, 255, 255, 0.6)
      background-color: #1a1a1a
      align-items: center
      padding: 1px 15px
      input
        outline: none
        border: 0
        background-color: #1a1a1a
        border-top-left-radius: 5px
        color: #9b9b9b
        border-bottom-left-radius: 5px
        height: 48px
        width: 268px
        font-size: 1.6rem
      input::placeholder
        color: #9b9b9b
      input:focus::placeholder
        outline: none
        color: transparent
    .whiteSearchWrapper
      box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.15)
      border: solid 1px #ebebeb
      background-color: #ffffff
      input
        background-color: #ffffff
    .buttonStyle
      margin: 5px 8px
      cursor: pointer
      width: 94px
      height: 40px
      object-fit: contain
      border-radius: 5px
      box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.15)
      background-color: #41126c
      background-color: rgba(65,18,108 , 0.2)
      font-size: 16px
      font-weight: bold
      line-height: 1.44
      color: #41126c
.whiteWrapper
  padding: 0 135px
