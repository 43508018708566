.modalContainer
  width: 422px
  // height: 378px
  object-fit: contain
  border-radius: 10px
  background-color: #ffffff
  display: flex
  flex-direction: column

  .modalHeader
    display: flex
    flex-direction: row
    justify-content: space-between
    margin: 20px

    p
      font-size: 20px
      font-weight: bold
      color: #1a1a1a

    .close
      cursor: pointer

      img
        width: 32px
        height: 32px

  .modalBody
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    flex: 1
    padding: 24px
    .successWrapper
      display: flex
      flex-direction: column
      align-items: center
      .imgSuccess
        width: 64px
        height: 64px
      .title
        font-size: 16px
        line-height: 1.56
        letter-spacing: 0.11px
        text-align: center
        color: #1a1a1a
        padding: 16px 72px 43px 72px
    .wrapper
      display: flex
      flex-direction: column
      .contactWrapper
        // width: 374px
        // height: 80px
        border-radius: 5px
        background-color: rgba(65, 18, 108, 0.2)
        padding-left: 24px
        display: flex
        flex-direction: row
        align-self: center
        align-items: center
        .contactTitle
          font-size: 16px
          line-height: 1.56
          letter-spacing: 0.11px
          flex: 5
          padding-top: 15px
          padding-bottom: 15px
          color: #41126c
          padding-left: 24px
          padding-right: 24px
        .phoneCall
          width: 34px !important
          height: 34px !important
      .btn
        width: 374px
        height: 48px
        border-radius: 5px
        background-color: #38c172
        font-size: 16px
        font-weight: bold
        line-height: 1.56
        letter-spacing: 0.11px
        text-align: center
        color: #ffffff

    .phoneWrapper
      margin-bottom: 16px

.phoneNumberWrapper
  cursor: pointer
  display: flex
  flex-direction: row
  justify-content: space-between
  width: 243px
  height: 49px
  border-radius: 24.5px
  background-color: #f6f6f6
  direction: ltr

  .phoneIcon
    width: 39px
    height: 39px
    border-radius: 19.5px
    background-color: #41126c
    margin: 5px

    img
      width: 24px
      height: 25px
      margin: 7px

  p
    font-size: 22px
    font-weight: 900
    color: #2d2d2d
    margin: auto

  &, &:hover
    text-decoration: none
  &.rtl
    direction: ltr
.rtl
  direction: rtl
  .contactWrapper
    padding-right: 24px
    padding-left: 0 !important
