.contentWrapper
  display: flex
  flex-direction: column
  padding: 20px 0

  .content
    padding: 10px 0
    display: flex
    border-bottom: solid 1px #e6e6e6
    justify-content: space-between
    cursor: pointer

    .title
      padding: 0 24px
      display: flex
      justify-content: flex-start
      font-size: 20px
      font-weight: bold
      //text-align: right
      color: #1a1a1a

    .arrow
      padding: 0 20px
  &.rtl
    .arrow
      transform: rotate(180deg)


.btn
  background-color: #ffffff
  padding: 0 24px
  font-size: 16px
  //text-align: right
  width: 100%
  height: 48px
  display: flex
  justify-content: flex-start
  color: #db3030
  margin-bottom: 24px

  &:active, &:focus
    outline: 0
