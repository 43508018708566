@import ../../../../sass/animations
.orderWrapper
  width: 100%
  .pickupDelivery
    .imgContent
      background-color: rgba(#f09440, 0.2)
  .pickupDelivery,
  .pickupOrders
    flex: 1
    height: 100%
    border-radius: 5px
    .imgContent
      display: flex
      width: 68px
      height: 100%
      border-radius: 5px
      justify-content: space-between
      img
        margin: auto
  .pickupOrders
    background-color: #ffffff
    .imgContent
      background-color: rgba(65, 18, 108,0.2)

  .orderExitWrapper
    transform-origin: 100% 0

  .contentWrapper
    flex: 20
    height: 88px
    margin-bottom: 16px
    display: flex
    flex-direction: row
    border-radius: 5px
    // justify-content: space-evenly
    box-shadow: 0 6px 12px 0 rgba(31, 4, 48, .1)
    width: 100%

    &.disappearing
      animation: collapse-and-disappear 0.4s

    .statusIcon
      width: 36px
      flex: 1
      background-color: #f7cc49
      align-items: center
      justify-content: center
      display: none

      img
        width: 25px

    .wrapper
      padding: 16px 24px
      flex: 20
      background-color: #ffffff
      display: flex
      flex-direction: row
      justify-content: space-evenly
      border-radius: 5px

      .pickUpTimeWrapper
        display: flex
        flex-direction: column !important
        flex: 1
      .pickupWrapper
        //width: 68px
        //height: 100%
        //opacity: 0.2
        //border-radius: 5px
        background-color: rgba(65,18,108,0.2)

      .orderCodeWrapper, .pickUpTimeWrapper, .userNameWrapper
        display: flex
        justify-content: center
        flex-direction: column
        align-items: flex-start
        flex: 1

        .userName
          font-size: 14px
          color: #a6a6a6

        .info
          font-size: 18px
          font-weight: bold
          color: #1a1a1a
        .discountWrapper
          display: flex
          flex-direction: row
          .discountTitle
            font-size: 12px
            font-weight: bold
            color: #41126c

      .userNameWrapper
        flex: 1.5

      .timer
        font-size: 14px
        color: #a6a6a6
        display: flex
        align-items: center
        // margin: 17px
        position: relative
        // left: 70px
    &, .wrapper
      background-color: #fff
    &.pendingWrapper
      &, .wrapper, .pickupOrders, .pickupDelivery, .statusIcon
        background-color: #f09440

      .wrapper
        .orderCodeWrapper, .pickUpTimeWrapper, .userNameWrapper
          .userName
            color: rgba(#ffffff, .7)

          .info
            color: #ffffff

        .timer
          color: rgba(#ffffff, .7)

    &.alarmWrapper
      &, .wrapper, .statusIcon, .pickupOrders, .pickupDelivery

        display: flex
        background-color: #db3030 !important

      .wrapper
        .orderCodeWrapper, .pickUpTimeWrapper, .userNameWrapper
          .userName
            color: rgba(#ffffff, .7)

          .info
            color: #ffffff

        .timer
          color: rgba(#ffffff, .7)
          position: relative
          // left: 99px
          // left: 62px
          .mosque
            position: absolute
            display: flex
            justify-content: flex-start
            width: 88px
            height: 88px
            top: -16px
            right: -27px
          &.rtl
            .mosque
              right: -15px
          // left: 27px

    &.customerArrivedWrapper
      .statusIcon
        display: flex

.contentWrapper
  &.rtl
    .pickupDelivery,
    .pickupOrders
      .alarm
        box-shadow: -2px 0 5px 0 rgba(144, 0, 0, 0.15)
      .pending
        box-shadow: -2px 0 5px 0 rgba(240, 148, 64, 0.15)

.pending
  background-color: rgb(240, 148, 64) !important
  box-shadow: 2px 0 5px 0 rgba(240, 148, 64, 0.15)

.normal
  background-color: rgba(65, 18, 108,0.2)
.alarm
  box-shadow: 2px 0 5px 0 rgba(144, 0, 0, 0.15)
  position: relative
  background-color: rgb(219, 48, 48) !important
