.content,.whiteModeContent
  display: flex
  justify-content: space-between
  width: 100%
  background-color: #212121
  margin: auto
  border-bottom: 1px solid #e6e6e6
  padding: 8px 2px
  .name
    font-size: 16px
    color: #ffffff
  .price
    display: flex
    justify-content: space-between
    span
      font-size: 16px
      font-weight: bold
      color: #ffffff
      margin: auto 35px
    .switch
      margin: auto 0
.is_available
  font-size: 12px
  font-weight: bold
  color: #db3030
  margin: 0 10px
.whiteModeContent
  background-color: #ffffff
  .name
    color: #1a1a1a
  .price
    span
      color: #1a1a1a
