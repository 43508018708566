.wrapper
  display: flex
  flex-direction: column
  align-items: flex-start

  label
    font-size: 14px
    font-weight: bold
    color: #2d2d2d

  .inputWrapper
    display: flex
    border-radius: 5px
    border: 1px solid transparent

    .button
      width: 48px
      height: 55px
      background-color: #d9d9d9
      outline: 0

      &:first-child
        border-top-left-radius: 5px
        border-bottom-left-radius: 5px

      &:last-child
        border-top-right-radius: 5px
        border-bottom-right-radius: 5px

    .input
      width: 89px
      height: 55px
      border: solid 1px #d9d9d9
      background-color: #ffffff
      font-size: 16px
      text-align: center
      color: #1a1a1a
      outline: 0

  &.invalid
    .inputWrapper
      border-color: #db3030

  &.valid
    .inputWrapper
      border-color: #38c172

  &.active
    .inputWrapper
      border-color: #fdbb11

  &.rtl
    direction: rtl

    .inputWrapper
      .button
        &:first-child
          border-top-right-radius: 5px
          border-bottom-right-radius: 5px
          border-top-left-radius: 0
          border-bottom-left-radius: 0

        &:last-child
          border-top-right-radius: 0
          border-bottom-right-radius: 0
          border-top-left-radius: 5px
          border-bottom-left-radius: 5px
