@import ../../../../../sass/colors

.dropDownPart
  display: flex
  flex-direction: column
  margin: 0
  span
    font-size: 1.6rem
    color: #222222
    position: relative
    margin: 0
  &:active, &:focus, &:hover
    border: 0
    outline: 0
  .dropdown
    width: 100%
    overflow-x: hidden
    overflow-y: scroll
    max-height: 350px
  .switch
    font-size: 1.6rem
    margin: 0
    max-height: 52px
    // width: 100%
    border-radius: 5px
    border: 0
    outline: 0
    background-color: #ffffff
    text-align: left
    padding: 0
    font-weight: 500
    color: #222222
    box-shadow: 0
    &:not(:disabled):not(.disabled)
      &:hover, &:active, &:focus
        border: 0
        box-shadow: none
        outline: 0
        background-color: #ffffff
    div
      display: flex
      align-items: center
      justify-content: space-between
      &:not(:disabled):not(.disabled)
        &:hover, &:active, &:focus
          box-shadow: none

  .switch:disabled
    background-color: #F3F3F3
    cursor: default

.item
  padding: 1rem
  text-align: center
  font-size: 1.8rem
  outline: 0
  white-space: normal
