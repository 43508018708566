.main, .whiteModeMain
  width: 100%
  background-color: #1a1a1a
  padding: 10px 92px
  .header
    display: flex
    align-items: center
    justify-content: space-between
    border-bottom: 3px solid #cccccc
    padding-bottom: 4px
    span
      font-size: 23px
      font-weight: bold
      color: #ffffff
.whiteModeMain
  background-color: #ffffff
  padding: 15px 135px
  .header
    span
      color: #1a1a1a
