
.wrapper
  display: flex !important
  justify-content: space-between !important
  align-items: center
  background-color: white
  margin: 0
  min-height: 88px
  cursor: pointer
  width: 100%
  border-bottom: 1px solid #d8d8d8
  &:hover,&:active
    background-color: rgba(240, 148, 64, 0.1)
  .inputWrapper
    display: flex
    flex-direction: row
    padding: 12px 0 12px 14px
    .labelWrapper
      display: flex
      flex-direction: column
      align-self: center
      .title
        color: #2d2d2d
        font-weight: 500
        font-size: 18px
        position: relative
        left: 20px
      .description
        width: 45.4rem
        font-size: 14px
        line-height: 1.79
        color: #a6a6a6
        position: relative
        left: 20px
  .input
    padding: 12px 0
    display: none
  .input[type='radio'] + span
    margin: 0 24px
    padding: 0
    width: 24px
    height: 24px
    border-radius: 50%
    cursor: pointer
    border: 1px solid #2d2d2d
    display: flex !important
    align-items: center !important
    justify-content: center !important
  .check
    width: 16px
    height: 16px
    border-radius: 50%
    background-color: #f09440
.rtl
  .inputWrapper
    padding-right: 14px
    .labelWrapper
      .title
        right: 8px
      .description
        right: 8px
