.renderSelectWrapper
  display: flex
  flex-direction: column

  &:focus, &:active
    border: none
    outline: none !important
    box-shadow: none !important

  .renderSelectReasonsWrapper
    height: 49px
    display: flex
    justify-content: space-around
    padding: 15px
    object-fit: contain
    border-radius: 5px
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, .1)
    border: solid 1px #a6a6a6
    background-color: #ffffff

  .renderSelect
    height: 49px
    display: flex
    justify-content: space-around
    padding: 15px
    object-fit: contain
    border-radius: 5px
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, .1)
    border: solid 1px #a6a6a6
    background-color: #ffffff

  .dropDownWrapper
    display: flex
    justify-content: space-between
    border: 0
    flex-direction: row

  .label
    display: flex
    align-self: flex-start
    font-size: 1.4rem
    font-weight: 500

.wrapper
  width: 100%
  max-width: 530px
  border-radius: 10px
  background-color: #ffffff
  max-height: 90vh
  overflow: auto
  outline: 0
  padding: 20px
  display: flex
  flex-direction: column

  // justify-content: space-around
  .separator
    margin: 3px 0

  .secondSeparator
    margin: 3.6% 0

  .close
    object-fit: contain
    display: flex
    cursor: pointer
    align-self: flex-end

  .title
    width: 100%
    font-size: 22px
    line-height: 1.14
    letter-spacing: .6px
    text-align: center
    color: #1a1a1a

  .form
    width: 100%
    display: flex
    flex-direction: column
    padding: 16px

    .dropDownSelect
      display: inline-block

    .btn
      border: none
      outline: 0
      height: 48px
      border-radius: 5px
      background-color: #38c172
      font-size: 16px
      font-weight: bold
      line-height: 1.56
      letter-spacing: .1px
      text-align: center
      color: #ffffff
      display: flex
      justify-content: center
      align-items: center
      margin: 13px 0
      cursor: pointer

    .reason
      width: 24px

  &.rtl
    text-align: right

.menuWrapper
  max-height: 300px
  overflow: auto

.secondMenuWrapper
