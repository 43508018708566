.modal
  display: flex
  justify-content: center
  align-items: center
  .wrapper
    background-color: #ffffff
    outline: 0
    // width: 568px
    border-radius: 10px
    .btnWrapper
      height: 96px
      padding: 0 37px
      display: flex
      justify-content: space-around

      align-items: center
      .save
        width: 520px
        height: 55px
        outline: 0
        border-radius: 5px
        background-color: #38c172
        font-size: 16px
        font-weight: bold
        line-height: 1.56
        letter-spacing: 0.11px
        text-align: center
        color: #ffffff
      .cancel
        width: 178.6px
        height: 48px
        border-radius: 5px
        background-color: #ffffff
        border: solid 1px #a6a6a6
        font-size: 16px
        font-weight: bold
        line-height: 1.56
        letter-spacing: 0.11px
        outline: 0
        text-align: center
        color: #1a1a1a
    .closeWrapper
      display: flex
      flex-direction: row
      justify-content: space-between
      padding: 24px
      .title
        font-size: 20px
        font-weight: bold
        color: #1a1a1a
      .icon
        cursor: pointer
        width: 32px
        height: 32px
    .iconWrapper
      display: flex
      justify-content: center
      align-items: center
      width: 48px
      height: 48px
      border-radius: 24px
      background-color: rgba(56, 193, 114, 0.15)
    .hideWrapper
      display: flex
      justify-content: center
      align-items: center
      width: 48px
      height: 48px
      border-radius: 24px
      background-color: rgba(219, 48, 48, 0.15)
    .clockWrapper
      display: flex
      justify-content: center
      align-items: center
      width: 48px
      height: 48px
      border-radius: 24px
      background-color: rgba(65, 18, 108, 0.15)

  .rtl
    direction: rtl
