.wrapper
  width: 422px
  height: 334px
  max-width: 100%
  object-fit: contain
  border-radius: 10px
  background-color: #ffffff
  padding: 20px
  display: flex
  flex-direction: column
  .close
    align-self: flex-start
    cursor: pointer
  .title
    font-size: 22px
    font-weight: bold
    line-height: 1.14
    letter-spacing: 0.16px
    text-align: center
    color: #1a1a1a
    margin: 0
  .optionsWrapper
    display: flex
    flex-direction: column
    padding: 32px 28px
    .optionButton
      padding: 12px 0
      border-radius: 5px
      background-color: #41126c
      font-size: 16px
      font-weight: bold
      line-height: 1.56
      letter-spacing: 0.11px
      text-align: center
      color: #ffffff
    .buttonsSeparator
      margin: 24px 0
      font-size: 16px
      font-weight: bold
      line-height: 1.56
      letter-spacing: 0.11px
      text-align: center
      color: #595959

