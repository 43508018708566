.wrapper
  width: 422px
  border-radius: 10px
  background-color: #ffffff
  display: flex
  flex-direction: column

  .settingsWrapper
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 24px
    border-bottom: solid 1px #e6e6e6

    .closeIcon
      display: flex
      justify-content: flex-start
      width: 32px
      height: 32px
      cursor: pointer

    .settingsTitle
      display: flex
      justify-content: flex-start
      font-size: 20px
      font-weight: bold
      //text-align: right
      color: #1a1a1a

    .codeWrapper
      display: flex
      //padding-left: 113px

      .code
        width: 81px
        height: 33px
        border-radius: 4px
        background-color: #f09440
        font-size: 18px
        font-weight: bold
        letter-spacing: .1px
        text-align: center
        color: #ffffff
        justify-content: center
        display: flex
        align-items: center

  &.rtl
    direction: rtl
