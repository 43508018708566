.main, .mainWhite
  height: 149px
  // box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.06)
  background-color: #3c3c3c
  padding-top: 30px
  position: sticky
  top: 72px
  z-index: 1
.mainWhite
  background-color: #fefefe
  top: 0
  padding-top: 0
